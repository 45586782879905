.ViewDietaryRestrictions {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    height: 100%;
    padding: 0 2.4rem 0 2.4rem;
    font-family: 'Lora', sans-serif;
}

.ViewDietaryRestrictions h1 {
    font-weight: unset;
    font-size: 2.4rem;
    margin: unset;
}

.ViewDietaryRestrictions h2 {
    font-weight: unset;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    margin: unset;
    font-family: 'Fjalla One', sans-serif;
}

.ViewDietaryRestrictions p {
    margin-top: 2rem;
    font-size: 1.8rem;
}

.ViewDietaryRestrictions .LinkButton {
    margin-bottom: 2.4rem;
}

.Line {
    padding: 2rem 0;
}