.LinkButton.Primary {
    background: var(--collegeChefsRed);
}
.LinkButton.Secondary:last-child {
    margin: 0 0 0 16px;
}
button.Secondary, .LinkButton.Secondary {
    background: var(--collegeChefsBlack);
}

.LinkButton:disabled,
button:disabled {
    color: white;
    background: var(--collegeChefsGray);
}

.LinkButton,
button {
    color:white;
    background: var(--collegeChefsRed);
    font-size: var(--inputFontSize);
    padding: 2.4rem 0 2.4rem 0;
    width: 100%;
    border-radius: var(--inputBorderRadius);
    font-family: 'Fjalla One', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    align-self: center;
    text-align: center;
    border: none;
}

.LinkButton:focus,
button:focus {
    border: 2px solid var(--collegeChefsRed);
    background: white;
    color: var(--collegeChefsRed);
    outline: none;
}

.LinkButton.Secondary:focus,
button.Secondary:focus {
    border-color: var(--collegeChefsBlack);
    color: var(--collegeChefsBlack);
}

.LinkButton.Cancel,
button.Cancel {
    border-color: var(--cancelButtonColor);
    background-color: var(--cancelButtonColor);
}
