.Footer {
    position: fixed;
    bottom: 0;
    background-color: var(--collegeChefsBlack);
    width: 100%;
    min-height: var(--footerHeight);
    display: flex;
    flex-direction: row;
}

.Icon {

}

.IconSelected {

}

.Footer .LinkButton {
    background: unset;
    border-radius: unset;
    padding: 2rem 0 2rem 0;
}

.Footer .LinkButton:focus {
    border:none;
}
