.RatingCTA {
    display: flex;
    flex-direction: column;
}

.RatingCTA__Separator {
    align-self: center;
    border: 0.15rem solid var(--collegeChefsRed);
    width: 75%;
    margin: 2rem;
}
