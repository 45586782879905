.MealDetailPage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
}

.MealDetailPage .XButton button {
    margin: 5.2rem 0 5.2rem 0;
}

.MealDetailPage .FullMenu {
    background-color: var(--collegeChefsLightGray);
    height: 100%;
    padding: 2rem 2.5rem 5rem 2.5rem;
}

.MealDetailPage .FullMenu h2 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 2.3rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.MealDetailPage .FullMenu ul {
    font-family: Helvetica, sans-serif;
    font-size: 1.6rem;
    line-height: 1.9rem;
    list-style: none;
    padding: 0;
}

.MealDetailPage .FullMenu ul > li {
    color: var(--collegeChefsGray);
    margin-bottom: 1.2rem;
}
