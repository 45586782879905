.MealRating {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 21rem;
}

.MealRating .RatingIcon {
    display: block;
}

.MealRating .RatingIcon svg path {
    fill: var(--ratingColor);
}

.MealRating .RatingIcon.Selected svg path {
    fill: var(--selectedRatingColor);
}
