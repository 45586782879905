
.FeatureCarousel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
}

.homeContent {
    display: flex;
    flex-direction: column;
    max-width: 330px;
    margin: 0 auto 5.2rem auto;
}

.FeatureCarousel .BannerImage {
    margin: 0;
    padding: 0;
    display: flex;
    height: 30rem;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom -40px center, top center;
    background-size: contain, cover;
}

.ButtonGroup {
    display: flex;
}

.FeatureIcon {
    margin-top: -80px;
    display: flex;
    align-self: center;
    justify-content: space-around;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    background: white;
    box-shadow: 1px 1px 13px var(--dropshadowColor);
}

.FeatureBody header {
    color: var(--collegeChefsBlack);
    font-family: var(--headerFontFamily);
    font-size: var(--headerFontSize);
    margin: 20px 0 20px 0;
}

.FeatureBody {
    color: var(--collegeChefsGray);
    font-size: var(--bodyFontSize);
}

.FeatureIndicators {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    margin: 40px 0 60px 0;
}

.FeatureIndicators .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: solid var(--collegeChefsDarkGray) 1px;
    background-color: var(--collegeChefsGray);
}

.FeatureIndicators .dot:not(:first-child){
    margin-left: 6px;
}

.FeatureIndicators .dot.active {
    border: solid var(--collegeChefsRed) 1px;
    background-color: var(--collegeChefsRed);
}
