.DayFilterComponent {
    display: flex;
    flex-direction: row;
    padding: 0 2.4rem 0.4rem 2.4rem;
    justify-content: space-between;
}

.DayFilterComponent button {
    max-width: 6rem;
    max-height: 6rem;
    background: white;
    border: 2px solid var(--collegeChefsBlack);
    box-sizing: border-box;
    border-radius: 50%;
    color: var(--collegeChefsBlack);
    font-size: 1.2rem;
    line-height: 1.2rem;
}

.DayFilterComponent button:focus {
    background: var(--collegeChefsRed);
    color: white;
    border: none;
}
