.RestrictionOption {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.RestrictionOption p {
    color: var(--collegeChefsBlack);
    margin: unset;
}

.RestrictionOption input {
    background-color: transparent;
    height: 28px;
    width: 28px;
    margin: unset;
    padding: unset;
}

.RestrictionOption {
    margin-bottom: 16px;
}

.RestrictionOption:last-child {
    margin-bottom: 20px;
}

.ThreateningAllergy {
    margin: unset;
    color: var(--collegeChefsRed);
}

.Visible {
    visibility: visible;
    margin: 0 0.8rem 0 0.8rem;
}

.RestrictionOption img.Hidden {
    visibility: hidden;
    margin: 0 0 0 0.8rem;
    height: 0;
    width: 0;
}
