.Register {
    text-align: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 0 2.4rem;
    font-family: 'Lora', sans-serif;
}

.RegisterPage {
    height: 100%;
}

.Register h1 {
    font-size: 2.4rem;
    font-weight: normal;
    margin: unset;
}

.Register p {
    font-size: 1.6rem;
}

.RegisterInputs {
    width: 100%;
}

.RegisterInputs input:last-child {
    margin-bottom: unset;
}

.RegisterInputs input {
    margin-bottom: 2.0rem;
}

.Arrow {
    font-size: 1.6rem;
}

.RegisterPage .CollegeChefLogo {
    margin: 0 auto;
}