.EndOfSemesterPage .CollegeChefLogo{
    margin:0 auto;
    margin-bottom: 2rem;
}

.EndOfSemester__attention{
    font-size: 3rem;
    margin:1rem 0;
}
.EndofSemester__explanation{
    margin: 2rem;
    font-size:2rem;
}
.EndOfSemester__CTA{
    font-size:1.8rem;
    margin-bottom: 0.4em;
}

.EndOfSemesterPage__Form{
    margin: 1rem 0;
}
.EndOfSemesterPage__HouseCode{
    margin-top: 3rem;
}
.EndOfSemesterPage__Logout{
    font-size:1.5rem;
    margin-top: 4rem;
}
.EndOfSemesterPage__Logout Button{
    background-color: var(--collegeChefsBlack);;
}
