.ViewLatePlatesPage {
    text-align: center;
    padding: 0 2.4rem;
    padding-bottom:100px;
}

.ViewLatePlatesPage_Subheader {
    font-family: 'Lora', sans-serif;
    font-size: 2.4rem;
    padding-top: 2rem;
}

.ViewLatePlatesPage .CollegeChefLogo {
    margin: 0 auto;
}
