.ProfilePage {
    height: 100%;
    padding: 0 2.4rem 0 2.4rem;
}

.InfoBlock {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--footerHeight);
}

.InfoBlock .ButtonGroup {
    flex-direction: column;
}

.ProfilePage .ButtonGroup .Secondary {
    margin-top: 1.8rem;
    color: white;
    background: var(--collegeChefsBlack);
}

.ProfilePage .CollegeChefLogo {
    margin: 0 auto;
}

.BuildInfo {
    text-align: center;
    font-size: 1.6rem;
}

.ReloadApp{
    border:solid 1px var(--collegeChefsGray);
    background: none;
    margin-top:10rem;
    padding: 1.5rem;
    text-align: center;
    color:var(--collegeChefsGray);
    font-size: 1.3rem;
    border-radius: 3px;
    cursor: pointer;
}
