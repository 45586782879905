.SetDietaryRestrictions {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Lora', sans-serif;
    padding: 0 2.4rem 0 2.4rem;
    justify-content: space-evenly;
}

.RestrictionOptions {
    display: flex;
    flex-direction: column;
    margin: 0 2.7rem 0 2.7rem;
    font-size: 1.6rem;
}

.SetDietaryRestrictions .LinkButton, button{
    margin: 1rem 0 1rem 0;
}

.RestrictionOptions:last-child {
    display: flex;
    flex-direction: column;
}

.SetDietaryRestrictions .LinkButton.Primary {
    margin: 2rem 0 2rem 0;
}

.SetDietaryRestrictions .LinkButton.Secondary {
    margin: 0 0 2.4rem 0;

}

.SetDietaryRestrictions p {
    color: var(--collegeChefsGray);
    margin: 1rem 0;
    font-size: 1.6rem;
}

.SetDietaryRestrictions h1 {
    margin: unset;
    margin-bottom: 1.6rem;
    font-weight: unset;
}

.SetDietaryRestrictions .PhoneForm {
   margin: 2rem 0 2rem 0;
}

.PhoneForm p {
    margin-bottom: 2rem;
}

.closeButtonFiller{
    height:73px;
}
