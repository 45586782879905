.MealRatingPage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    margin: 0 3.6rem;
}

.MealRatingPage .XButton button {
    margin: 5.2rem 0 5.2rem 0;
}

.MealRatingPage h1 {
    margin-bottom: 3.2rem;
}

.MealRatingPage .MealRating {
    margin-bottom: 5.2rem;
}

.MealRatingPage__Comment {
    margin-bottom: 2rem;
    min-height: 8.6rem;
}
