.RequestLatePlatePage {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
}

.RequestLatePlatePage .XButton button {
    margin: 5.2rem 0 5.2rem 0;
}

.RequestLatePlatePage__Confirmed {
    align-self: center;
    width: 26rem;
    height: 26rem;
}

.RequestLatePlatePage__Content {
    font-family: Lora sans-serif;
    font-size: 2.4rem;
    line-height: 3.1rem;
    letter-spacing: -0.02em;
}

.RequestLatePlatePage p {
    color: var(--collegeChefsLightBlack);
    margin: 3rem 6rem;
}

.RequestLatePlatePage .CollegeChefLogo {
    margin: 0 auto;
}

.RequestLatePlatePage__Content  {
    margin-bottom: 10rem;
}
