.LifeThreateningInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 2.4rem;
    align-items: center;
}

.LifeThreateningInfo .XButton {
    margin-top: 3.2rem;
}

.LifeThreateningInfo .Heading {
    color: var(--collegeChefsBlack);
    font-family: "Lora", sans-serif;
    font-size: 2.4rem;
    letter-spacing: -0.02em;
    line-height: 3.1rem;
    text-align: center;
}

.LifeThreateningInfo .Body {
    color: var(--collegeChefsGray);
    font-size: 1.6rem;
    line-height: 2rem;
    min-height: 11rem;
    text-align: center;
}
