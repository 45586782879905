.MealsDayPage {
    text-align: center;
    padding-bottom: 4rem;
}

.MealsDayPage .CollegeChefLogo {
    margin: 0 auto;
}

.NoMealsDay {
    margin: 50% 0;
    font-size: 1.5rem;
    color: var(--collegeChefsDarkGray);
}

.Meals__Day {
    padding: 2.4rem;
}
