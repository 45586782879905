html, body {
  height:100%;
  margin:0;
}
#root{
  margin:0;
  height: 100%;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --standardText: --collegeChefsBlack;
}

:root {
  --collegeChefsBlack: #161717;
  --collegeChefsLightBlack: #303030;
  --collegeChefsRed: #D84127;
  --collegeChefsDarkRed: #801300;
  --collegeChefsGray: #919191;
  --collegeChefsLightGray: rgba(145, 145, 145, 0.08);
  --collegeChefsDarkGray: #717171;
  --cancelButtonColor: #9F6A3C;
  --selectedMealColor: #EFE8E0;
  --mealsWeekSeperatorColor: #EFE8E0;
  --selectedRatingColor: #F2C94C;
  --ratingColor: var(--collegeChefsGray);

  --dropshadowColor: rgba(0, 0, 0, 0.25);
  --inputBorderRadius: 6rem;
  --inputFontSize: 1.6rem;

  --headerFontFamily: 'Lora', sans-serif;
  --headerFontSize: 2.4rem;

  --bodyFontFamily: sans-serif;
  --bodyFontSize: 1.6rem;

  /*This line will set 1rem = 10px*/
  font-size: 62.5%;
}

h1 {
  color: var(--collegeChefsBlack);
  font-family: "Lora", sans-serif;
  font-size: 2.4rem;
  font-weight: normal;
  letter-spacing: -0.02em;
  line-height: 3.1rem;
}

h2 {
  color: var(--collegeChefsBlack);
  font-family: "Fjalla One", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 2.4rem;
  text-transform: uppercase;
}

textarea {
  background-color: var(--collegeChefsLightGray);
  color: var(--collegeChefsBlack);
  border: 0.2rem solid var(--collegeChefsGray);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  padding: 2.6rem;
}

input {
  display: block;
  border: 0.2rem solid var(--collegeChefsGray);
  box-sizing: border-box;
  border-radius: var(--inputBorderRadius);
  padding: 2.4rem 3.2rem;
  font-size: var(--inputFontSize);
  width: 100%;
  color: var(--collegeChefsRed);
  font-weight: bold;
  height: 5rem;
  margin: unset;
}

input:focus{
  border: 2px solid var(--collegeChefsRed);
  outline: none;
}

input:focus::placeholder{
  color: var(--collegeChefsRed);
}

.PageWithFooter {
  --footerHeight: 10vh;
  --footerMargin: 1rem;
  margin-bottom: calc(var(--footerHeight) + var(--footerMargin));
}

/* Animate elements fading in by adding this class to divs */
.fadein{
  animation: fadein 1s;
}
@keyframes fadein {
  from { opacity: 0; transform:scale(0.7);}
  to   { opacity: 1; transform:scale(1);}
}
