.MealsPage {
    text-align: center;
    padding-bottom: 4rem;
    --headerPadding: 2.4rem;
    --headerHeight: 24rem;
}


.MealsPage__PinnedHeader {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 100;
    border-bottom: 2px solid var(--mealsWeekSeperatorColor);
}

.MealsPage__Date {
    font-family: 'Lora', sans-serif;
    font-size: 2.4rem;
}

.MealsPage .CollegeChefLogo {
    margin: 0 auto;
}

.Meals {
    padding: calc(var(--headerHeight) + var(--headerPadding)) 2.4rem 2.4rem 2.4rem;
}

.NoMealsWeek {
    margin: 50% 0;
    font-size: 1.5rem;
    color: var(--collegeChefsDarkGray);
}
