.UserInfo {
    padding: 2.6rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', sans-serif;
    font-size: 1.5rem;
}

.PlateInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    font-family: 'Lora', sans-serif;
    font-size: 2rem;
}

.PlateCount {
    font-size: 6rem;
    color: var(--collegeChefsRed);
}

.UserInfo h1 {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    font-weight: unset;
}

.UserInfo p{
    margin: unset;
    color: var(--collegeChefsGray);
}

.PlateInfo p {
    text-align:center;
    margin: unset;
    padding-top: 2rem;
}

.PlateInfo h1 {
    margin: unset;
    font-weight: unset;
}
