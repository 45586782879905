
.LatePlate {
    display: flex;
    flex-direction: row;
    margin-top: 5.7rem;
}

.LatePlate .MealIcon {
    margin: 3.2rem 2rem auto;
}

.LatePlate__MealDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.LatePlate__Date {
    align-self: flex-start;
    font-family: var(--bodyFontFamily);
    font-size: 1.4rem;
    line-height: 3.2rem;
    font-weight: bold;
    letter-spacing: -0.02em;

    color: var(--collegeChefsBlack);
}

.LatePlate__Name {
    font-family: var(--headerFontFamily);
    font-style: normal;
    font-weight: normal;
    font-size: 3.6rem;
    line-height: 4.6rem;
    letter-spacing: -0.02em;

    /* CC-Red */
    color: var(--collegeChefsRed);
}

.LatePlate__Entree {
    font-family: var(--headerFontFamily);
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: left;

    color: var(--collegeChefsLightBlack);
}

.LatePlate:last-child {
    margin-bottom: 10rem;
}

.LatePlate:first-child {
    margin-top: 2.5rem;
}
