.MealIcon {
}

.MealIcon svg {
    margin-top:-25px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 1.3rem var(--dropshadowColor);
}

.MealIcon.Active svg path {
    fill: var(--collegeChefsRed);
}

.MealIcon .LinkButton {
    padding: unset;
    display: flex;
    background: unset;
    justify-content: center;
    border: none;
}

.MealDetailPage .MealIcon {
}
